import { render, staticRenderFns } from "./Matrix.vue?vue&type=template&id=21479640"
import script from "./Matrix.vue?vue&type=script&lang=js"
export * from "./Matrix.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeMatrixImage: require('/home/userapp/components/Molecule/Matrix/MatrixImage.vue').default,MoleculeMatrixVideo: require('/home/userapp/components/Molecule/Matrix/MatrixVideo.vue').default,MoleculeMatrixFoldout: require('/home/userapp/components/Molecule/Matrix/MatrixFoldout.vue').default,MoleculeMatrixForm: require('/home/userapp/components/Molecule/Matrix/MatrixForm.vue').default,MoleculeMatrixBanner: require('/home/userapp/components/Molecule/Matrix/MatrixBanner.vue').default,MoleculeMatrixCarousel: require('/home/userapp/components/Molecule/Matrix/MatrixCarousel.vue').default,MoleculeMatrixDiscoveries: require('/home/userapp/components/Molecule/Matrix/MatrixDiscoveries.vue').default,MoleculeMatrixEvents: require('/home/userapp/components/Molecule/Matrix/MatrixEvents.vue').default,MoleculeMatrixSearchEvents: require('/home/userapp/components/Molecule/Matrix/MatrixSearchEvents.vue').default,MoleculeMatrixOverviewSectionEntries: require('/home/userapp/components/Molecule/Matrix/MatrixOverviewSectionEntries.vue').default,MoleculeMatrixText: require('/home/userapp/components/Molecule/Matrix/MatrixText.vue').default,MoleculeMatrixDiscoveryShelf: require('/home/userapp/components/Molecule/Matrix/MatrixDiscoveryShelf.vue').default,MoleculeMatrixCollectionShelf: require('/home/userapp/components/Molecule/Matrix/MatrixCollectionShelf.vue').default,MoleculeMatrixVriendenLoterijVipCard: require('/home/userapp/components/Molecule/Matrix/MatrixVriendenLoterijVipCard.vue').default,MoleculeMatrixNewsletter: require('/home/userapp/components/Molecule/Matrix/MatrixNewsletter.vue').default,MoleculeMatrixWidget: require('/home/userapp/components/Molecule/Matrix/MatrixWidget.vue').default})
